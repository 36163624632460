import Text from "../../Text/Text";
import ErrorBot from "../../Icons/ErrorBot";
import { useContext } from "react";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";


export default function VerifyEmail({ setStep }) {
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const usercontext = useContext(UserContext);
  const setShowModal = usercontext.setShowModal;
  const setModalType = usercontext.setModalType;

  const showSignupModal = () => {
    setModalType("signin");
    setShowModal(true);
  };
  return (
    <div className="w-full sm:w-[350px] md:w-[400px] lg:w-[450px] xl:w-[700px] pt-[10px] md:pt-[10px] lg:pt-[15px] xl:pt-[20px] relative pb-6 mx-auto">
      <div className="successBot mx-auto flex justify-center relative">
        {/* <div className="absolute bottom-2">
          <ErrorBot />
        </div> */}
      </div>

      <div className="text-center">
        <Text
          as="h2"
          className="text-[20px] sm:text-[20px] md:text-[26px] lg:text-[32px] xl:text-[40px] mt-4 mb-4 font-semibold text-black"
        >
          {t.VerificationRequired}
        </Text>
        <Text
          as="p"
          className={`text-md text-grey800 font-medium ${
            locale === "ar" ? "rtl-input" : ""
          }`}
        >
          {t.PleaseVerifyYourEmail}
        </Text>
      </div>

      <div className="flex mt-4 justify-center mx-auto mt-12 mb-8 w-[90%] sm:[80%] md:w-[170px] lg:w-[200px] xl:w-[224px]">
        <div className="flex-auto flex justify-center">
          <button
            className="btn btn-green btn-block rounded-full"
            onClick={() => showSignupModal()} // Adjust this to navigate to the intended step or dismiss the modal
           >
            {t.OkGotIt}
          </button>
        </div>
      </div>
    </div>
  );
}
